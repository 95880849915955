import pdfFonts from "pdfmake/build/vfs_fonts";
import { fetchIconsBase64, getData } from "../feathers";
import { format, parseISO } from "date-fns";
import { employmentObligationOptions } from "../Companies/CompanyForm";
import { logDefault } from "./logger";
import { formatDate } from "../snippets";
//eslint-disable-next-line @typescript-eslint/no-var-requires
const lineColor = '#D8DCE0';
const cardFillColor = '#F9F9F9';
const color = '#494C4F';
const fontSize_XS = 8;
const fontSize_S = 10;
const fontSize = 12;
const fontSize_M = 14;
const fontSize_L = 16;

const htmlToPdfmake = require("html-to-pdfmake");

const defaultStyles = { // change the default styles
	h1: { fontSize: fontSize_M },
	h2: { fontSize: fontSize },
	p: { margin: [0, 1, 0, 1] },
	ol: { marginBottom: 5 },
	li: { marginLeft: 30 },
}
const styles = {
	headerdate: { fontSize: fontSize_XS, color },
	tableBody: { fontSize: fontSize_S, color },
	smallerText: { fontSize: fontSize_S, color },
	largerText: { fontSize: fontSize_L },
	normalText: { fontSize: fontSize },
	normalTextBold: { fontSize: fontSize, bold: true, color },
	headline: {
		bold: true,
		fontSize: fontSize,
		color,
		margin: [0, 10, 0, 5]
	},
};

const tableLayoutCard = {
	hLineWidth: function (i, node) { return 1 }, vLineWidth: function (i, node) { return 1 },
	hLineColor: function (i, node) { return lineColor }, vLineColor: function (i, node) { return lineColor },
	paddingLeft: function (i, node) { return 8; }, paddingRight: function (i, node) { return 8; },
	paddingTop: function (i, node) { return 8 }, paddingBottom: function (i, node) { return 8 }
}
const tableHeadlineLayout = {
	hLineWidth: function (i, node) { return 1 }, vLineWidth: function (i, node) { return 0 }, hLineColor: function (i, node) { return lineColor }
}
const tableLayoutCaseEventHeadline = {
	vLineWidth: function (i, node) { return (i === 0 || i === node.table.widths.length) ? 1 : 0 },
	paddingLeft: function (i, node) { return 5; }, paddingRight: function (i, node) { return 5; },
	paddingTop: function (i, node) { return 5 }, paddingBottom: function (i, node) { return 5 },
	hLineColor: function (i, node) { return '#aaaaaa' }, vLineColor: function (i, node) { return '#aaaaaa' },
}

async function createCompanyContent(company, images, eaaModules) {
	const imageCompany = images.filter((entry) => entry.alias === 'Company')[0].base64;
	const imageCaseUsers = images.filter((entry) => entry.alias === 'Shield')[0].base64;
	const content = []
	content.push(createTableHeadline(imageCompany, 'Unternehmen', [0, 0, 0, 10], createTableSheet('COMPANY', company, 150)));
	/* export contact-persosn */
	if (company.contactPersons && company.contactPersons.length > 0) {
		content.push(createTableHeadline(imageCaseUsers, 'Anprechpartner*in', [0, 0, 0, 10], createCompanyContactPersons(company.contactPersons)));
	}

	/* export Addresses */
	if (company.addresses && company.addresses.length > 0) {
		content.push(createTableHeadline(imageCaseUsers, 'Adressen', [0, 0, 0, 10], await createCompanyAddresses(company.addresses)));
	}

	/* export Case-Users */
	if (company.companyUsers && company.companyUsers.length > 0) {
		content.push({ text: '', margin: [0, 10, 0, 0] });
		const imageCaseUsers = images.filter((entry) => entry.alias === 'Shield')[0].base64;
		content.push(createTableHeadline(imageCaseUsers, 'Bearbeiter*innen', [0, 0, 0, 10], createCaseUsers(company.companyUsers, imageCaseUsers)));
	}

	/* export Associated-Contacts */
	if (company.associatedContacts && company.associatedContacts.length > 0 && eaaModules.ASSOCIATEDCONTACTS === '1') {
		content.push({ text: '', margin: [0, 10, 0, 0] });
		const imageAssociatedContacts = images.filter((entry) => entry.alias === 'Associated-Contact')[0].base64;
		content.push(createTableHeadline(imageAssociatedContacts, 'Netzwerkkontakte', [0, 0, 0, 10], createAssociatedContacts(company.associatedContacts, imageAssociatedContacts)));
	}
	return content;
}

async function createJobOpeninContent(jobOpening, images) {
	const imageJobOpening = images.filter((entry) => entry.alias === 'JobOpening')[0].base64;
	const imageCompany = images.filter((entry) => entry.alias === 'Company')[0].base64;
	const imageEaaCase = images.filter((entry) => entry.alias === 'EAA-Case')[0].base64;
	const content = []
	content.push(createTableHeadline(imageJobOpening, 'Stellenprofil', [0, 0, 0, 10], createTableSheet('JOBOPENING', jobOpening, 150)));
	const html = htmlToPdfmake(jobOpening.explanation, { defaultStyles })
	content.push(getDefaultTable([html], [20, 0, 0, 20]));
	content.push(createTableHeadline(imageCompany, 'Unternehmen', [0, 0, 0, 10], createTableSheet('COMPANY', jobOpening.company, 150)));

	if (jobOpening.eaaCase) {
		content.push(createTableHeadline(imageEaaCase, 'Fall', [0, 0, 0, 10], createTableSheet('EAACASE', jobOpening.eaaCase, 150)));
		const htmlEaaCase = htmlToPdfmake(jobOpening.eaaCase.description, { defaultStyles })
		content.push(getDefaultTable([htmlEaaCase], [20, 0, 0, 20]));
	}
	return content;
}

async function createEAACaseContent(eaaCase, images, eventClassifierStructure, eaaModules) {
	const imageEaaCase = images.filter((entry) => entry.alias === 'EAA-Case')[0].base64;
	const imageCompany = images.filter((entry) => entry.alias === 'Company')[0].base64;

	const html = htmlToPdfmake(eaaCase.description, { defaultStyles })
	const content = [
		createTableHeadline(imageEaaCase, 'Fall', [0, 0, 0, 10], createTableSheet('EAACASE', eaaCase, 150)),
		getDefaultTable([html], [20, 0, 0, 20])
	]

	/* export Company */
	content.push(createTableHeadline(imageCompany, 'Unternehmen', [0, 0, 0, 10], createTableSheet('COMPANY', eaaCase.company, 150)));

	/* export Case-Person */
	const imageCasePerson = images.filter((entry) => entry.alias === 'User')[0].base64;
	if (eaaCase.casePerson) {
		content.push(createTableHeadline(imageCasePerson, 'Person', [0, 0, 0, 10], createTableSheet('CASEPERSON', eaaCase.casePerson, 150)));
	} else {
		content.push(createTableHeadline(imageCasePerson, 'Person', [0, 0, 0, 10], { text: '- Zur Zeit des Exports waren keine Daten vorhanden -', fontSize: fontSize_S, margin: [0, 10, 0, 0], alignment: 'center' }));
	}

	/* export Case-Users */
	if (eaaCase.caseUsers && eaaCase.caseUsers.length > 0) {
		content.push({ text: '', margin: [0, 10, 0, 0] });
		const imageCaseUsers = images.filter((entry) => entry.alias === 'Shield')[0].base64;
		content.push(createTableHeadline(imageCaseUsers, 'Bearbeiter*innen', [0, 0, 0, 10], createCaseUsers(eaaCase.caseUsers, imageCaseUsers)));
	}

	/* export Associated-Contacts */
	if (eaaCase.associatedContacts && eaaCase.associatedContacts.length > 0 && eaaModules.ASSOCIATEDCONTACTS === '1') {
		content.push({ text: '', margin: [0, 10, 0, 0] });
		const imageAssociatedContacts = images.filter((entry) => entry.alias === 'Associated-Contact')[0].base64;
		content.push(createTableHeadline(imageAssociatedContacts, 'Netzwerkkontakte', [0, 0, 0, 10], createAssociatedContacts(eaaCase.associatedContacts, imageAssociatedContacts)));
	}

	/* export Case-Requests */
	if (eaaCase.caseRequests && eaaCase.caseRequests.length > 0) {
		content.push({ text: '', margin: [0, 10, 0, 0] });
		const imageCaseRequests = images.filter((entry) => entry.alias === 'Case-Request')[0].base64;
		content.push(createTableHeadline(imageCaseRequests, 'Anträge', [0, 0, 0, 0]))
		content.push(eaaCase.caseRequests.map(caseRequest => getDefaultTable([createTableSheet('CASEREQUEST', caseRequest, 150)], [0, 0, 0, 10], true)))
		//content.push(createCaseRequests(eaaCase.caseRequests, imageCaseRequests));
	}

	/* export Case-Events */
	if (eaaCase.caseEvents && eaaCase.caseEvents.length > 0) {
		const imageCalendar = images.filter((entry) => entry.alias === 'Calendar')[0].base64;
		const imageClock = images.filter((entry) => entry.alias === 'Clock')[0].base64;
		const imageCheckbox = images.filter((entry) => entry.alias === 'Checkbox')[0].base64;
		const imageContact = images.find((entry) => entry.alias === 'Contact').base64;
		content.push(createTableHeadline(imageContact, 'Kontakte', [0, 0, 0, 10]))
		content.push(createCaseEvents(eaaCase.caseEvents, imageCalendar, imageClock, imageCheckbox, eventClassifierStructure));
	}

	return content
}

function createCaseEvents(caseEvents, imageCalendar, imageClock, imageCheckbox, eventClassifierStructure) {
	const content = [];
	caseEvents.forEach(caseEvent => {
		const eventClassifiers = caseEvent.caseEventClassifiers ? caseEvent.caseEventClassifiers : [];
		const html = htmlToPdfmake(caseEvent.description, { defaultStyles });
		const bodyContent = [[createCaseEventHeadline(caseEvent, imageCalendar, imageClock)], [createEvemtClassifiers(eventClassifiers, imageCheckbox, eventClassifierStructure)], [getDefaultTable([html], [20, 0, 0, 20])]];
		content.push(getDefaultTable([bodyContent]));
	});

	return content;
}

function createEvemtClassifiers(eventClassifiers, imageCheckbox, eventClassifierStructure) {

	const content = [];
	const allItems = [];
	const eventClassifiersList = eventClassifiers;

	if (!eventClassifierStructure) {
		return ''
	}
	const classifierStructure = eventClassifierStructure.map((structure) => {
		structure.items.forEach((item) => allItems.push(item))
		const structureItems = structure.items.map(item => item.caseEventClassifier)
		const items = eventClassifiersList.filter(item => structureItems.includes(item.caseEventClassifier))
		return { ...structure, items }
	}).filter(structure => structure.items.length > 0)

	classifierStructure.forEach((structure) => {
		const items = structure.items;
		items.sort((a, b) => {
			let x = a.caseEventClassifier.toLowerCase();
			let y = b.caseEventClassifier.toLowerCase();
			if (x < y) { return -1; }
			if (x > y) { return 1; }
			return 0;
		})
		const bodyContent = [];
		items.forEach((item) => {
			const structureItem = allItems.find(entry => entry.caseEventClassifier === item.caseEventClassifier);
			let value = null;
			if (structureItem.fieldType === 'NUMBER') {
				value = { text: '' + item.count, style: 'tableBody' }
			} else if (item.count === 1) {
				value = { image: "data:image/png;base64," + imageCheckbox, width: 10, margin: [0, 2, 0, 0] };
			}
			if (value) {
				bodyContent.push([value, { text: structureItem.display, style: 'tableBody' }]);
			}
		})
		if (bodyContent.length > 0) {
			const tableStructure = {
				stack: [{
					margin: [5, 0, 0, 0], layout: 'noBorders',
					table: {
						dontBreakRows: true, widths: [20, '*'], body: bodyContent
					}
				}]
			}
			content.push({
				stack: [{
					margin: [10, 0, 0, 0],
					layout: 'noBorders',
					table: {
						dontBreakRows: true,
						widths: ['*'], body: [[
							{
								stack: [{
									layout: 'noBorders',
									table: {
										dontBreakRows: true, widths: ['*'], body: [[{ text: structure.headline, margin: [0, 0, 0, 2], color }], [tableStructure]]
									}
								}]

							}
						]]
					}
				}]
			}
			)
		}
	})
	if (content.length > 0) {
		content.unshift({ margin: [10, 10, 0, 0], text: 'Geschlüsselte statistische Werte', bold: true, fontSize: fontSize_S })
	}
	return content.length === 0 ? [] : {
		margin: [20, 0, 0, 0], layout: 'noBorders',
		table: {
			widths: ['*'], body: [[{ fillColor: cardFillColor, stack: content }]]
		}
	}
}

function createCaseEventHeadline(caseEvent, imageCalendar, imageClock) {
	const text = 'Ersteller*in: ' + caseEvent.creator.displayname;
	return {
		layout: tableLayoutCaseEventHeadline,
		margin: [0, 0, 0, 5],
		table: {
			widths: [100, 8, 60, 8, 40, '*'], dontBreakRows: true,
			body: [[
				{ text: caseEvent.eventType.acoordionHeader, style: 'tableBody' },
				{ image: "data:image/png;base64," + imageCalendar, width: 10, margin: [0, 1, 0, 0], alignment: 'right' },
				{ text: format(parseISO(caseEvent.eventDate), 'dd.MM.yyyy'), style: 'tableBody' },
				{ image: "data:image/png;base64," + imageClock, width: 10, margin: [0, 1, 0, 0], alignment: 'right' },
				{ text: format(parseISO(caseEvent.eventDate), 'HH:mm'), style: 'tableBody' },
				{ text, style: 'tableBody', alignment: 'right' }],
			]
		}
	}
}

async function createCompanyAddresses(addresses) {
	const rows = addresses.length % 2 === 0 ? parseInt(addresses.length / 2) : parseInt(addresses.length / 2) + 1;
	const bodyTable = [];
	for (let i = 0; i < rows; i++) {
		const address1 = addresses[i * 2];
		const address2 = (i * 2 + 1 < addresses.length ? addresses[i * 2 + 1] : null);
		const column1 = await createAddressCard(address1);
		const column2 = await createAddressCard(address2);
		if (i !== 0) {
			bodyTable.push([{ colSpan: 3, border: [false, false, false, false], text: '' }, {}, {}])
		}
		bodyTable.push([column1, { border: [false, false, false, false], text: '' }, column2]);
	}
	return [{ layout: tableLayoutCard, margin: [0, 5, 0, 20], table: { dontBreakRows: true, widths: ['*', 50, '*'], body: bodyTable } }];
}

async function createAddressCard(address) {
	let border = [false, false, false, false];
	const stack = [];
	if (address) {
		await getData('addresses', address.id).then((address) => {
			border = [true, true, true, true];
			stack.push({ text: address.addressType.display, style: 'normalTextBold', margin: [0, 0, 0, 5] });
			if (address.line1) { stack.push({ text: address.line1, style: 'smallerText' }) }
			if (address.line2) { stack.push({ text: address.line2, style: 'smallerText' }) }
			if (address.line3) { stack.push({ text: address.line3, style: 'smallerText' }) }
			if (address.postcode || address.city) { stack.push({ text: address.postcode + ' ' + address.city, style: 'smallerText' }) }
			if (address.country) { stack.push({ text: address.country, style: 'smallerText' }) }
		})
	}
	return !address ? { border, text: '' } : { border, fillColor: cardFillColor, stack };
}

function createCompanyContactPersons(contactPersons) {
	const rows = contactPersons.length % 2 === 0 ? parseInt(contactPersons.length / 2) : parseInt(contactPersons.length / 2) + 1;
	const bodyTable = [];
	for (let i = 0; i < rows; i++) {
		const contactPerson1 = contactPersons[i * 2];
		const contactPerson2 = (i * 2 + 1 < contactPersons.length ? contactPersons[i * 2 + 1] : null);
		const column1 = createContactPersonCard(contactPerson1);
		const column2 = createContactPersonCard(contactPerson2);
		if (i !== 0) {
			bodyTable.push([{ colSpan: 3, border: [false, false, false, false], text: '' }, {}, {}])
		}
		bodyTable.push([column1, { border: [false, false, false, false], text: '' }, column2]);
	}
	return [{ layout: tableLayoutCard, margin: [0, 5, 0, 20], table: { dontBreakRows: true, widths: ['*', 50, '*'], body: bodyTable } }];
}

function createContactPersonCard(contactPerson) {
	let border = [false, false, false, false];
	const stack = [];
	if (contactPerson) {
		border = [true, true, true, true];
		stack.push({ text: contactPerson.lastname + ', ' + contactPerson.firstname + ' - ' + contactPerson.contactType.display, style: 'normalTextBold' });
		stack.push({ text: contactPerson.jobDescription, style: 'normalTextBold', margin: [0, 0, 0, 5] });
		if (contactPerson.phone) { stack.push({ text: contactPerson.phone, style: 'smallerText' }) }
		if (contactPerson.mobile) { stack.push({ text: contactPerson.mobile, style: 'smallerText' }) }
		if (contactPerson.fax) { stack.push({ text: contactPerson.fax, style: 'smallerText' }) }
		if (contactPerson.mail) { stack.push({ text: contactPerson.mail, style: 'smallerText' }) }

	}
	return !contactPerson ? { border, text: '' } : { border, fillColor: cardFillColor, stack };
}

function createCaseUsers(caseUsers) {
	const rows = caseUsers.length % 2 === 0 ? parseInt(caseUsers.length / 2) : parseInt(caseUsers.length / 2) + 1;
	const bodyTable = [];
	for (let i = 0; i < rows; i++) {
		const caseUser1 = caseUsers[i * 2];
		const caseUser2 = (i * 2 + 1 < caseUsers.length ? caseUsers[i * 2 + 1] : null);
		const column1 = createCaseUserCard(caseUser1);
		const column2 = createCaseUserCard(caseUser2);
		if (i !== 0) {
			bodyTable.push([{ colSpan: 3, border: [false, false, false, false], text: '' }, {}, {}])
		}
		bodyTable.push([column1, { border: [false, false, false, false], text: '' }, column2])
	}
	return [{ layout: tableLayoutCard, margin: [0, 5, 0, 20], table: { dontBreakRows: true, widths: ['*', 50, '*'], body: bodyTable } }];
}

function createCaseUserCard(caseUser) {
	const stack = [];
	let border = [false, false, false, false];
	if (caseUser) {
		border = [true, true, true, true];
		stack.push({ text: caseUser.user.lastname + ', ' + caseUser.user.firstname, style: 'normalTextBold' });
		stack.push({ text: caseUser.roleType.display, style: 'normalTextBold', margin: [0, 0, 0, 5] });
		if (caseUser.user.phonenumber) { stack.push({ text: caseUser.user.phonenumber, style: 'smallerText' }) }
		if (caseUser.user.mobilenumber) { stack.push({ text: caseUser.user.mobilenumber, style: 'smallerText' }) }
		if (caseUser.user.email) { stack.push({ text: caseUser.user.email, style: 'smallerText' }) }

	}
	return !caseUser ? { border, text: '' } : { border, fillColor: cardFillColor, stack };
}

function createAssociatedContacts(associatedContacts) {
	const rows = associatedContacts.length % 2 === 0 ? parseInt(associatedContacts.length / 2) : parseInt(associatedContacts.length / 2) + 1;
	const bodyTable = [];
	for (let i = 0; i < rows; i++) {
		const associatedContact1 = associatedContacts[i * 2];
		const associatedContact2 = (i * 2 + 1 < associatedContacts.length ? associatedContacts[i * 2 + 1] : null);
		const column1 = createAssociatedContactCard(associatedContact1);
		const column2 = createAssociatedContactCard(associatedContact2);
		if (i !== 0) {
			bodyTable.push([{ colSpan: 3, border: [false, false, false, false], text: '' }, {}, {}])
		}
		bodyTable.push([column1, { border: [false, false, false, false], text: '' }, column2])
	}
	return [{ layout: tableLayoutCard, margin: [0, 5, 0, 20], table: { dontBreakRows: true, widths: ['*', 50, '*'], body: bodyTable } }];
}

function createAssociatedContactCard(associatedContact) {
	const stack = [];
	let border = [false, false, false, false];
	if (associatedContact) {
		border = [true, true, true, true];
		if (associatedContact.organization) { stack.push({ text: associatedContact.organization, style: 'normalTextBold' }) }
		if (associatedContact.name) { stack.push({ text: associatedContact.name, style: 'normalTextBold', margin: [0, 0, 0, 5] }) }
		if (associatedContact.phone) { stack.push({ text: associatedContact.phone, style: 'smallerText' }) }
		if (associatedContact.mobile) { stack.push({ text: associatedContact.mobile, style: 'smallerText' }) }
		if (associatedContact.email) { stack.push({ text: associatedContact.email, style: 'smallerText' }) }

	}
	return !associatedContact ? { border, text: '' } : { border, fillColor: cardFillColor, stack };
}

async function createPDF(objectName, exportObject, eventClassifierStructure, eaaModules) {

	try {
		const images = await fetchIconsBase64();
		const logo = images.filter((entry) => entry.alias === 'EAA-Logo')[0].base64;
		let header = null;
		let content = [];

		if (objectName === 'EAACASE') {
			const title = `${exportObject.name}`
			const subtitle = `${exportObject.caseDocId} vom ${format(parseISO(exportObject.date), 'dd.MM.yyyy')} - ${exportObject.status.display}`
			header = createPDFHeader(logo, title, subtitle);
			content = await createEAACaseContent(exportObject, images, eventClassifierStructure, eaaModules)
		} else if (objectName === 'COMPANY') {
			const title = `${exportObject.name}`
			const subtitle = `#UN${exportObject.id} erstellt am ${format(parseISO(exportObject.createdAt), 'dd.MM.yyyy')} - ${exportObject.active ? 'Aktiv' : 'Inaktiv'}`
			header = createPDFHeader(logo, title, subtitle);
			content = await createCompanyContent(exportObject, images, eaaModules)
		} else if (objectName === 'JOBOPENING') {
			const title = `${exportObject.description}`
			const subtitle = `#UN${exportObject.id} erstellt am ${format(parseISO(exportObject.createdAt), 'dd.MM.yyyy')} -  ${exportObject.status.display}`
			header = createPDFHeader(logo, title, subtitle);
			content = await createJobOpeninContent(exportObject, images)
		}


		const pdfMake = require('pdfmake');
		pdfMake.vfs = pdfFonts.pdfMake.vfs;

		const docDefinition = {
			defaultStyles, styles, pageMargins: [80, 100, 50, 50],
			info: { title: `EAA Export vom ${format(new Date(), 'yyyy-MM-dd')}`, author: 'EAA' },
			footer: function (currentPage, pageCount, pageSize) {
				return [
					{ text: 'Seite ' + currentPage.toString() + ' / ' + pageCount, alignment: 'right', style: 'headerdate', margin: [0, 20, 25, 0] }]
			},
			header,
			content
		}
		logDefault('exportToPDF', 'info', 'docDefinition', docDefinition)
		pdfMake.createPdf(docDefinition).open();

	} catch (error) {
		if (error) { console.error(error) };
	}
}

function createPDFHeader(logo, title, subtitle) {
	const table = {
		margin: [10, 10, 10, 0], layout: 'noBorders',
		table: {
			widths: [80, '*', 100],
			body: [
				[{}, {}, { text: `Export vom ${format(new Date(), 'yyyy-MM-dd')}`, alignment: 'right', style: 'headerdate' }],
				[{ image: "data:image/png;base64," + logo, width: 50 }, { color, fontSize: (title && title.length > 150 ? fontSize_S : fontSize), text: title ? title : '', alignment: 'center' }, {}],
				[{}, { text: subtitle ? subtitle : '', alignment: 'center', style: { fontSize: fontSize_S, color }, margin: [0, 2, 0, 0] }, {}]
			]
		}
	}

	return (currentPage, pageCount, pageSize) => {
		return [
			{
				table: {
					heights: pageSize.height / 2,
					widths: [15, '*'],
					body: [[{ border: [false, false, false, true], text: '' }, { border: [false, false, false, false], stack: [table] }]]
				},
			},

		]
	}
}

function createTableHeadline(image, text, margin, content) {
	if (!margin) {
		margin = [0, 0, 0, 20];
	}

	const rows = [[{ image: "data:image/png;base64," + image, border: [false, false, false, true], width: 15, margin: [0, 1, 0, 0] }, { text, border: [false, false, false, true], bold: true, color }]];
	if (content) {
		rows.push([{ colSpan: 2, stack: [content], border: [false, false, false, false] }, {}])
	}
	return {
		layout: 'noBorders',
		table: {
			widths: ['*'], dontBreakRows: true, body: [
				[{
					style: 'headline',
					layout: tableHeadlineLayout, margin,
					table: { widths: [20, '*'], dontBreakRows: true, body: rows }
				}]
			]
		}
	}
}

function getDefaultTable(body, margin, dontBreakRows) {
	margin = margin || [0, 0, 0, 10]
	return {
		layout: 'noBorders', margin,
		table: {
			widths: ['*'], dontBreakRows: (dontBreakRows ? true : false), body: [
				[{
					layout: 'noBorders',
					table: { widths: ['*'], dontBreakRows: false, body: body ? [body] : [[{ text: '' }]] },
				}],
			]
		}
	}
}

function createTableSheet(objectName, exportObject, width) {
	const body = [];
	if (objectName === 'EAACASE') {
		body.push(['Fallnamen:', exportObject.name]);
		if (exportObject.company) {
			body.push(['Namen des Unternehmens:', exportObject.company.name]);
		}
		body.push(['Verantwortliche*r:', exportObject.responsible ? (exportObject.responsible.displayname || exportObject.responsible) : '']);
		body.push(['Ersteller*in:', exportObject.creator ? exportObject.creator.displayname : '']);
		body.push(['Initiiert durch:', exportObject.initiatedBy ? exportObject.initiatedBy.display : '']);
		body.push(['Dateipfad Dokument:', exportObject.documentLocation || '']);
	} else if (objectName === 'COMPANY') {
		body.push(['Namen des Unternehmens:', exportObject.name || ''])
		body.push(['Telefon:', exportObject.phone || ''])
		body.push(['Fax:', exportObject.fax || ''])
		body.push(['E-Mail:', exportObject.mail || ''])
		body.push(['Mitarbeiter:', exportObject.employees || '']);
		body.push(['Mitarbeiter SbM:', exportObject.employeesSbM || '']);
		body.push(['Kammer:', exportObject.chamber || ''])
		body.push(['Betriebsnummer:', exportObject.chamberNo || ''])
		body.push(['EAA Region:', exportObject.region ? exportObject.region.name : '']);
		const employees = exportObject.employees && exportObject.employees > 0 ? exportObject.employees : 0;
		const employeesSbM = exportObject.employeesSbM ? exportObject.employeesSbM : 0;
		const quote = employees > 0 ? 100 / employees * employeesSbM : 0;
		body.push(['Quote:', (employees < 60 ? Math.ceil(quote) : Math.floor(quote)) + '%']);
		body.push(['Beschäftigungspflicht:', employmentObligationOptions.find(entry => entry.value === exportObject.employmentObligation).display]);
	} else if (objectName === 'CASEPERSON') {
		body.push(['Person ID:', exportObject.personId || '']);
		body.push(['Grad der Behinderung Merkzeichen:', exportObject.GdBMark || ''])
		body.push(['Grad der Behinderung:', exportObject.GdB || ''])
		body.push(['Art der Behinderung:', exportObject.disablityKind || ''])
		body.push(['Geschlecht:', exportObject.gender ? exportObject.gender.display : ''])
	} else if (objectName === 'CASEREQUEST') {
		body.push([{ style: 'normalText', text: 'Name des Antrags:' }, { text: exportObject.name || '', style: 'normalTextBold' }]);
		body.push(['Antragsnummer:', 'AN' + exportObject.id.toString().padStart(5, "0")])
		body.push(['Status:', exportObject.status.display || ''])
		body.push(['Art:', exportObject.caseRequestType.name || ''])
		body.push(['Antragsdatum:', exportObject.requestDate ? format(parseISO(exportObject.requestDate), 'yyyy-MM-dd') : ''])
		body.push(['Erledigungsdatum:', exportObject.completionDate ? format(parseISO(exportObject.completionDate), 'yyyy-MM-dd') : '-'])
		body.push(['Erstellt von:', exportObject.creator ? exportObject.creator.displayname : ''])
	} else if (objectName === 'JOBOPENING') {
		body.push(['Stelle:', exportObject.description || '']);
		body.push(['Namen des Unternehmens:', exportObject.company.name]);
		body.push(['Fall:', exportObject.eaaCase ? exportObject.eaaCase.name : ''])
		body.push(['Adresse:', exportObject.location || ''])
		body.push(['Startdatum:', exportObject.openingDate ? format(parseISO(exportObject.openingDate), 'yyyy-MM-dd') : ''])
		body.push(['Typ:', exportObject.jobType ? exportObject.jobType.display : ''])
		body.push([' ', ' '])
		body.push(['Gehalt:', exportObject.salary || '']);
		body.push(['Arbeitsstunden:', exportObject.workingHours || '']);
		body.push(['Geschäftsreisen:', exportObject.businessTravelRequired ? 'ja' : 'nein']);
		body.push(['Ausgeschl. Bhd:', exportObject.excludedDisabilities || '']);
		body.push(['Mobilität:', exportObject.mobilityRequired ? 'ja' : 'nein']);
		body.push(['Mobilitätstyp:', exportObject.mobilityType || '']);
		body.push(['Status:', exportObject.status ? exportObject.status.display : '']);

	}
	return {
		layout: 'noBorders',
		style: 'tableBody',
		margin: [0, 5, 0, 0],
		table: {
			dontBreakRows: true,
			widths: [width, 440 - width],
			body
		}
	}
}



const exportToPDF = async (objectName, exportObject, eventClassifierStructure, eaaModules) => {
	createPDF(objectName, exportObject, eventClassifierStructure, eaaModules);
}

export default exportToPDF;