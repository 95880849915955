import { useState } from 'react';
import { Column } from "primereact/column"
import { DataTable } from "primereact/datatable"
import { useFormatDate } from "../Persistence/ConfigurationsContext"
import { InputNumber } from "primereact/inputnumber"
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import { getSortedList } from '../snippets';
import { ConfirmDialog } from '../Components/ConfirmDialog';

export const getUserFTECurrentValue = (formik, values) => {
	formik.setFieldValue('userFTEs', getSortedList(values, 'date', true))
	const list = values.filter(e => !e.delete)
	if (list.length > 0) {
		formik.setFieldValue('dateLastFTE', list[0].date)
		formik.setFieldValue('lastFTE', list[0].fte)
	}

}

export const UserFormFTE = ({ formik }) => {
	const formatDate = useFormatDate();
	const [newDate, setNewDate] = useState(null)
	const [newFTE, setNewFTE] = useState(null)
	const [deleteFTE, setDeleteFTE] = useState(null)
	const [displayDeleteConfirmDialog, setDisplayDeleteConfirmDialog] = useState(false);

	const onRowEditComplete = (e) => {
		const values = (formik.values.userFTEs || []).map((entry) => {
			if (entry.id === e.newData.id) {
				entry.date = e.newData.date
				entry.fte = e.newData.fte
			}
			return entry
		})
		getUserFTECurrentValue(formik, values)
		formik.setFieldValue('editmode', false)
	}

	const addFTE = () => {
		const values = formik.values.userFTEs || []
		values.push({ id: null, date: newDate, fte: newFTE })
		setNewDate(null)
		setNewFTE(null)
		getUserFTECurrentValue(formik, values)
		formik.setFieldValue('editmode', false)
		formik.setFieldValue('editmode', false)
	}

	const confirmDeleteFTE = (id) => {
		const fte = (formik.values.userFTEs || []).find(e => e.id === id)
		if (fte) {
			setDeleteFTE(fte)
			setDisplayDeleteConfirmDialog(true)
		}
	}

	const hanledDeleteFTE = () => {
		const values = (formik.values.userFTEs || []).map((e) => {
			{
				return e.id === deleteFTE.id ? { ...e, delete: true } : e
			}
		})
		setDeleteFTE(null)
		setNewDate(null)
		setNewFTE(null)
		getUserFTECurrentValue(formik, values)
		formik.setFieldValue('editmode', false)

		formik.setFieldValue('editmode', false)
	}

	const fteEditor = (options) => {
		return <InputNumber value={options.value}
			className='w-6rem'
			inputClassName="w-4rem"
			onValueChange={(e) => options.editorCallback(e.value)}
			min={0} step={0.1} max={1}
			showButtons
			maxFractionDigits={2}
			minFractionDigits={2}
			mode="decimal"
			locale="de-DE" />
	}
	const textEditor = (options) => {
		return <Calendar key={'calendar-date-fte'}
			mask={'99.99.9999'}
			value={options.value}
			readOnlyInput={true}
			onChange={(e) => { options.editorCallback(e.value) }}
			dateFormat="dd.mm.yy"
		/>
	}

	return <>
		<ConfirmDialog title='FTE löschen' message={`Wollen Sie denFTE-Eintrag vom ${deleteFTE ? formatDate(deleteFTE.date) : ''} löschen?`} labelOk='Ja'
			handleOnClick={hanledDeleteFTE} displayConfirmDialog={displayDeleteConfirmDialog} setDisplayConfirmDialog={setDisplayDeleteConfirmDialog} />
		<div className="hidden w-full">
			<div className='flex col'>Neuer Eintrag:</div>
		</div>
		<div className="flex w-full mb-3">

			<div className='flex col-fixed border-1 border-gray-100 border-right-none' style={{ width: '160px' }}><Calendar
				className='w-9rem '
				key={'calendar-date-fte'}
				mask={'99.99.9999'}
				value={newDate}
				onChange={(e) => { setNewDate(e.value) }}
				dateFormat="dd.mm.yy"
			/></div>

			<div className='flex col-fixed border-1 border-gray-100 ' style={{ width: '145px' }}><InputNumber value={newFTE}
				className='w-6rem'
				inputClassName="w-4rem"
				onValueChange={(e) => setNewFTE(e.value)}
				min={0} max={1} step={0.1}
				showButtons
				maxFractionDigits={2}
				minFractionDigits={2}
				mode="decimal"
				locale="de-DE" />
			</div>
			<div className='flex col p-2'>
				<Button
					label='hinzufügen'
					className='w-full'
					onClick={addFTE}
					disabled={!newDate || newFTE === null} />
			</div>
		</div>
		<div className="formgrid grid  m-0 p-0">
			<DataTable className='userform-fte w-full'
				size="small"
				showGridlines
				editMode='row'
				onRowEditInit={(row) => { console.log('row:', row); formik.setFieldValue('editmode', true) }}
				onRowEditComplete={onRowEditComplete}
				value={(formik.values.userFTEs || []).filter(e => !e.delete)}>
				<Column
					className='w-9rem'
					header='Datum'
					field='date'
					editor={(options) => textEditor(options)}
					body={(row) => ('seit: ' + formatDate(row.date))}
				/>
				<Column
					className='w-8rem'
					header='FTE'
					field='fte'
					body={(row) => row.fte.toLocaleString('de-DE')}
					editor={(options) => fteEditor(options)}
				/>
				<Column rowEditor headerClassName="w-8rem" bodyStyle={{ textAlign: 'center' }}></Column>
				{!formik.values.userFTEs || formik.values.userFTEs.length < 2 ? undefined : <Column
					headerClassName="w-4rem"
					bodyStyle={{ textAlign: 'center' }}
					body={(rowData) => { return (rowData.delete ? '' : <i className='pi pi-trash' onClick={() => confirmDeleteFTE(rowData.id)} />) }}
				/>}
			</DataTable>
		</div>
	</>

}