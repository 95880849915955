import { useEffect, useState } from "react";
import { DropdownChangesChecked } from '../../Components/DropdownChangesChecked';
import { EditorChangesChecked } from '../../Components/EditorChangesChecked';
import { InputChangesChecked } from '../../Components/InputChangesChecked';
import { useConfigurationsList } from '../../Persistence/ConfigurationsContext';
import { useCurrentUser, useCurrentUserIsReaderFBiB } from '../../Persistence/CurrentUserContext';
import { logDefault } from "../../Utils/logger";
import { AutoCompleteChangesChecked } from "../../Components/AutoCompleteChangesChecked";
import client, { findData, getData } from "../../feathers";
import { ENUM_SERVICES } from "../../Enums/ENUM_SERVICES";
import { InputNumberChecked } from "../../Components/InputNumberChecked";
import { useBreadcrumbsAdd } from "../../Persistence/Breadcrumbs";

export const emptyTrainingCourse = { id: null, version: 1, active: 1, name: '', description: '', type: '', userId: null, trainingCourseCheck: false, documentLocation: '', duration: 0, existingTrainingCourse: 0, chamberId: 1 }

const CLASSNAME = 'TrainingCourseForm'
export const TrainingCourseForm = ({ formik, parentTrainingCourseList, handleSelectExistingTrainingCourse }) => {
	const isReader = useCurrentUserIsReaderFBiB();
	const currentUser = useCurrentUser();
	const addBreadcrumb = useBreadcrumbsAdd();
	const configurationsList = useConfigurationsList();
	const [mounted, setMounted] = useState(false)
	const [trainingCourseList, setTrainingCourseList] = useState([])

	useEffect(() => {
		findData(ENUM_SERVICES.TRAININGCOURSE, { active: 1, chamberId: formik.values.chamberId }).then((trainingCourseList) => {
			setTrainingCourseList(trainingCourseList);
			handleTrainingCourseBlur({ target: { value: formik.values.name } })
			setMounted(true);
		})
	}, [formik.values.chamberId])

	useEffect(() => {
		logDefault(CLASSNAME, 'info', 'formik values:', formik.values)

	}, [isReader, formik.values])

	const handleTrainingCourseChange = async (e) => {
		console.log('handleTrainingCourseChange: ' + e.value)

		if (e.value && typeof e.value === 'object' && e.value.constructor === Object) {
			const trainingCourse = await getData(ENUM_SERVICES.TRAININGCOURSE, e.value.id)
			const values = { ...trainingCourse, trainingCourseCheck: false }
			if (handleSelectExistingTrainingCourse) {
				handleSelectExistingTrainingCourse(e.value.id, addBreadcrumb);
			} else {
				formik.setValues(values)
			}
		} else {
			await handleTrainingCourseBlur({ target: { value: e.value || e.target.value } }).then(() => {
				formik.setFieldValue('name', e.value || e.target.value)
			})
		}
	}

	const handleTrainingCourseBlur = async (e) => {
		console.log('handleTrainingCourseBlur: ' + e.target.value)
		const query = `SELECT name from training_course WHERE name LIKE '${e.target.value.trim()}' AND id != ${formik.values.id || 0} AND chamberId =${formik.values.chamberId}`
		await client.service(ENUM_SERVICES.UTILS).create({ method: 'rawQuery', query }).then(async (result) => {
			await formik.setValues({
				...formik.values,
				name: formik.values.name.trim(),
				existingTrainingCourse: result.length > 0 ? 1 : 0
			})

		})
	}

	return <div className='provider-activities-form col' >
		{!formik || !mounted ? '' : <div className="formgrid grid">
			<div className="field col  mb-0">
				<div className="formgrid grid">
					<DropdownChangesChecked
						className='mr-3'
						options={configurationsList.TrainingCourseTypeList}
						disabled={false}
						formik={formik}
						id='type'
						optionValue='alias'
						itemLabel='display'
						label='Typ'
					/>
					<DropdownChangesChecked
						className='mr-3'
						options={configurationsList.ChamberList}
						disabled={false}
						formik={formik}
						id='chamberId'
						optionValue='id'
						itemLabel='display'
						label='Kammer'
					/>
				</div>
				<div className="formgrid grid">
					{formik.values && !formik.values.id ? <>
						<AutoCompleteChangesChecked
							dropdown={true}
							label={'Name des Ausbildungsgangs'}
							id={'name'}
							itemLabel={'name'}
							formik={formik}
							forceSelection={false}
							suggestionsList={trainingCourseList}
							helptextcolor='#11b9a0'
							helptext={'Ein Ausbildungsgang mit diesem Namen ist der Datenbank nicht bekannt. Beim Speichern wird der Ausbildungsgang mit den eingegebenen Daten in der Datenbank eingetragen.'}
							onBlur={handleTrainingCourseBlur}
							className='p-0 m-0'
							onFocus={() => formik.setFieldValue('trainingCourseCheck', true)}
							handleChange={handleTrainingCourseChange} />
					</> :
						<InputChangesChecked
							id='name'
							type="text"
							formik={formik}
							editmode={true}
							className='mr-0'
							handleChange={handleTrainingCourseChange}
							label='Name des Ausbildungsgangs' />
					}
				</div>
				<div className="formgrid grid">

				</div>
				<div className="formgrid grid">
					<InputNumberChecked
						inputStyle={{ height: '35px' }}
						id='duration'
						label='Dauer in Monaten'
						type="text"
						formik={formik}
						editmode={true}
					/>
					<InputChangesChecked id='documentLocation' label='Dateipfad für Dokumentation' type="text" formik={formik} editmode={true} />
				</div>
				<div className="formgrid grid">
					<EditorChangesChecked id='description' value={formik.values.description} setValue={(value) => formik.setFieldValue('description', value)} label='Beschreibung des Ausbildungsgangs' required={false} />
				</div>
			</div>
		</div>}
	</div>
}

export const validateTrainingCourse = (data) => {
	let errors = {};
	if (data.existingTrainingCourse === 1) {
		errors.name = 'Ausbildungsgang mit diesem Namen bereits vorhanden.';
	}
	if (!data.name) {
		errors.name = 'Der Name des Ausbildungsgangs muss gesetzt sein!';
	}
	if (!data.type) {
		errors.type = 'Der Typ des Ausbildungsgangs muss gesetzt sein!';
	}
	return errors;
}