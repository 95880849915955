import { endOfDay, format, getTime } from 'date-fns';
import { Calendar } from 'primereact/calendar';
import { useRef, useEffect, useState } from 'react';
import { logDefault } from '../../Utils/logger';
import { useFBiBMode } from '../../Persistence/ConfigurationsContext';

const CLASSNAME = 'DateFilterElement';
export const DateFilterElement = ({ options, minDate, maxDate }) => {
	const calendarRef = useRef();
	const fbibMode = useFBiBMode();
	const [filterValues, setFilterValues] = useState();
	useEffect(() => {
		logDefault(CLASSNAME, 'info', 'options', options)
	}, [])

	return (
		<div className={(options && options.value && options.value.length > 0 ? 'eaa-filter-active' : '') + ' flex w-8rem filterCreatedCalendar'}>
			<div id='displayEnddateFilterDates' className="flex flex-column flex-grow-1 justify-content-start font-normal text-sm pr-2 m-0  display-dates">
				<span className='flex justify-content-center'>{options.value && options.value[0] ? format(options.value[0], 'dd.MM.yyyy') : null}</span>
				<span className='flex justify-content-center'>{options.value && options.value[1] ? format(options.value[1], 'dd.MM.yyyy') : null}</span>
			</div>
			<div className='flex overflow-hidden' style={{ width: '38px' }}>
				<Calendar id='calendarFilterDates'
					style={{ minWidth: '100px' }}
					showIcon iconPos='left'
					value={filterValues}
					ref={calendarRef}
					dateFormat="dd.mm.yy"
					selectionMode='range'
					panelClassName={fbibMode ? 'fbib' : ''}
					readOnlyInput
					minDate={minDate}
					maxDate={maxDate}
					onChange={(e) => {
						const values = [e.value[0] ? getTime(e.value[0]) : null, e.value[1] ? getTime(endOfDay(e.value[1])) : null]
						setFilterValues(e.value)
						options.filterApplyCallback(values);
						if (e.value[1] !== null) { calendarRef.current.hide() }
					}}
				/>
			</div>

		</div>
	)
}